<template>
  <div class="back" @click="$router.go(-1)">
    <i class="fa fa-2x fa-arrow-left"></i>
  </div>
  <div class="forward" @click="$router.go(1)">
    <i class="fa fa-2x fa-arrow-right"></i>
  </div>
</template>

<script>
export default {
  name: "router",
};
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: none;
  cursor: pointer;
  transition: all 0.3s;
}

.back {
  left: 0;
  transform: translate(-49px, 0);
}

.forward {
  right: 0;
  transform: translate(49px, 0);
}

div:hover {
  background: rgb(0, 0, 0, 0.3);
  transform: translate(0, 0);
}
</style>
